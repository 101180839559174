<template>
   <article class="app-not-found">
      <img class="app-not-found__img" src="@/assets/images/not-found.gif" alt="Товар не найден" />

      <span class="app-not-found__text">Странно, но ничего нет</span>
   </article>
</template>

<script>
   export default {
      name: 'AppNotFound'
   };
</script>

<style lang="sass" scoped>
   .app-not-found
     display: flex
     flex-direction: column
     justify-content: center
     align-items: center
     row-gap: 15px
     width: 100%
     margin: 140px auto

   .app-not-found__img
     width: 75px

   .app-not-found__text
     font-family: "Open Sans", sans-serif
     font-size: 14px
     font-weight: 600
</style>
